import React, { Component } from 'react'
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import * as Scroll from 'react-scroll';
import './ArrowDown.scss';
import { type } from 'os';

let Link       = Scroll.Link;
let Element    = Scroll.Element;
let Events     = Scroll.Events;
let scroll     = Scroll.animateScroll;
let scrollSpy  = Scroll.scrollSpy;

class ArrowDown extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
      isIE: false,
    };

  }
  componentDidMount() {
    this.setState({
      isIE: '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style
    })
    Events.scrollEvent.register('begin', function(to, element) {
      
    });
    Events.scrollEvent.register('end', function(to, element) {
      
    });
    scrollSpy.update();
  }
  
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  
  onClick = event => { 
    this.scrollToNextSection(event);
  }
  
  scrollToNextSection(e) {
    // if automode is set to true then let the arrow down handle scrolling down the page.
    // if automode is disabled then you must specify your own scrollMore when using arrowdown
    if (this.props.autoMode) {
      let windowH = window.innerHeight;
      scroll.scrollMore(windowH);
    } else {
      return null;
    }
    // if ((typeof(nextSectionId) !== 'undefined' || typeof(nextSectionId) !== '' || typeof(nextSectionId) === null ) && (nextSectionId !== '#')) {
    //   document.getElementById(nextSectionId).scrollIntoView();
    // } else {
    //   let parentSection = e.target.closest('section');
    //   let nextSection = parentSection.nextElementSibling;
    //   while (nextSection.tagName != 'SECTION') {
    //     nextSection = this.getNextSection(nextSection);
    //   }
    //   const y = nextSection.getBoundingClientRect().top + window.scrollY;
    //   if (!this.state.isIE) {
    //     window.scroll({
    //       top: y,
    //       behavior: 'smooth'
    //     });
    //   } else {
    //     nextSection.scrollIntoView()
    //   }
    // }
  }

  getNextSection(element) {
    return element.nextElementSibling;
  }

  render() {
    const { className, width, height, nextSectionId, autoMode } = this.props;    
    return (
      <div className={className ? className : 'arrow-down'} onClick={()=> this.scrollToNextSection({autoMode, nextSectionId})}>
        <a to={nextSectionId ? nextSectionId : ''}>
          <img width={width} height={height} src="/white_Scroll_Down.svg" />
        </a>
      </div>
    )
  }
}

export default ArrowDown;