import React from 'react';
import './Icons.scss';
// import Image from '../components/Image';
// import { Link } from 'gatsby'
import OnVisible, { setDefaultProps } from 'react-on-visible'; 

setDefaultProps({
    percent: 25
});

export const Icons = props => {
  const { 
    site,
    iconsPerRow,
    theme,
    icons
  } = props

  const { wordpressUrl } = site.siteMetadata;

  return (
    <section className={`Icons ${iconsPerRow} ${theme}`}>
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            { icons.map( (item, index) => {
              const {
                icon,
                heading,
                description
              } = item;

              return (
                <OnVisible className="icon" key={index}>
                {/* <div className="icon" key={index}> */}
                  <div className="image-container">
                    <img className={`image`} src={`${wordpressUrl}${icon.source_url}`} />
                  </div>
                  <span className="heading" dangerouslySetInnerHTML={{__html: heading}} />
                  <span className="description" dangerouslySetInnerHTML={{__html: description}} />
                {/* </div> */}
                </OnVisible>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
