import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby';
// import './PostList.scss';
import Pagination from '../components/Pagination';
import { Image } from '../components/Image';
import GatsbyLink from '../components/GatsbyLink'
import './CaseStudies/CaseStudiesList.scss';

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, pageContext, categories, pathPrefix, siteMetadata} = this.props
    return (
      <>
      {/* <div className="navigation">
        <strong>Filter By: </strong>
        <GatsbyLink to={`/${siteMetadata.blogSlug}/`} activeClassName="active">All</GatsbyLink>
        {categories && categories.map(({node: category} , index) => {
          return (
            <GatsbyLink key={index} to={`/${siteMetadata.blogSlug}/category/${category.slug}/`} activeClassName="active">{category.name}</GatsbyLink>
          );
        })}
      </div> */}
      <section className="CaseStudiesList">
        <div className="content-overlay">
          <div className="flex-row">
            {posts.map(({ node: post }) => {
              const {id, featuredImage, title, slug, author, date} = post;
              return (
                <div className="flex-item" key={id}>
                  <GatsbyLink
                    to={`/${siteMetadata.blogSlug}/${slug}/`}
                    decode={false}
                  >
                    <div className="image-container">
                      <Image className="featured-image" src={featuredImage} />
                    </div>
                    <div className="text-container">
                      <span className="title" dangerouslySetInnerHTML={{__html: title}} />
                      <div className="read-more">
                        <span className="text">Read More</span>
                        <div className="overflow-hidden">
                          <div className="arrow"></div>
                        </div>
                      </div>
                    </div>
                  </GatsbyLink>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
      </>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    featuredImage: featured_image_url {
      source_url
    }
    categories {
      id
      name
      slug
    }
    author {
      name
      slug
    }
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
  }
`
