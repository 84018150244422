import React from 'react';
import './IconsAndText.scss';
import { Image } from '../components/Image';
import { Link } from 'gatsby'
import OnVisible, { setDefaultProps } from 'react-on-visible';

setDefaultProps({
    percent: 30
});

export const IconsAndText = props => {
  const {
    site,
    iconsPlacement,
    theme,
    subheading,
    heading,
    content,
    button,
    buttonText,
    panels
  } = props

  const { wordpressUrl } = site.siteMetadata;

  return (
    <section className={`IconsAndText ${iconsPlacement} ${theme}`}>
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <div className="content image-box">
              { panels.map( (item, index) => {
                const {
                  panelHeading,
                  panelSubheading,
                  icons
                } = item;

                return(
                  <OnVisible className="panel" key={index}>
                  {/* <div className="panel" key={index}> */}
                    <h2 className="title" dangerouslySetInnerHTML={{__html: panelHeading}} />
                    <p className="description" dangerouslySetInnerHTML={{__html: panelSubheading}} />
                    <div className="icons-container">
                      { icons.map( (item, index) => {
                        const {
                          icon,
                          label
                        } = item;

                        return(
                          <div className="icon" key={index}>
                            <img className={`image`} src={`${wordpressUrl}${icon.source_url}`} />
                            <span className="label" dangerouslySetInnerHTML={{__html: label}} />
                          </div>
                        );

                      })}
                    </div>
                  {/* </div> */}
                  </OnVisible>
                );
              })}
            </div>
            <OnVisible className="content text-box tb2">
            {/* <div className="content text-box tb2"> */}
              <span className="subtitle" dangerouslySetInnerHTML={{__html: subheading}} />
              <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} />
              <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: content}} />
              {button && (
                    <Link
                      to={button}
                      activeClassName="active"
                      className={`button ${theme === 'dark' ? 'on-dark' : ''}`}
                      aria-current="true"
                    >
                      <span>{ buttonText ? buttonText : 'Learn More' }</span>
                    </Link>
                )}
              {/* </div> */}
              </OnVisible>
          </div>
        </div>
      </div>
    </section>
  )
}
