import React from 'react';
import OnVisible, { setDefaultProps } from 'react-on-visible'
import './HomeTextContentArea.scss';
import { Image } from '../../components/Image';
import ArrowDown from '../../components/ArrowDown/ArrowDown';

export const HomeTextContentArea = props => {
  const { contentCopy, button, buttonText, toggleOptions, backgroundImage } = props.Content[0];
  return (
    <section className={'home-text-content'} id={'home_text_content_area'}>
      <div className={props.className ? `${props.className} wrapper` : 'content-copy-wrapper wrapper'}>
        <div className={'content-copy'}>
          <h2 dangerouslySetInnerHTML={{__html: contentCopy}} />
        </div>
        <a className='button' href={button}>{buttonText}</a>
        {toggleOptions[0].toggleArrowDown && <ArrowDown autoMode={true}/>}
      </div>
      <div className="background-image__wrapper">
      { backgroundImage && (
        <Image className="background-image" src={backgroundImage} />
      ) }
      </div>
    </section>
  )
}
