import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import OnVisible from 'react-on-visible'
import { Image } from '../../components/Image'
import GatsbyLink from '../../components/GatsbyLink'
import { Carousel } from '../../components/Carousel/Carousel';
import { decodeEntities } from '../../utils/htmlParse';
import './FeaturedProjectsSliderFeed.scss'
import './AliceCarousel.scss'



class ProjectsFeedOutput extends React.Component {

  state = {
    isMobileView: false,
    dotsDisabled: true,
    stagePadding: {
      paddingLeft: 250,
      paddingRight: 250,
    },
  }

  componentDidMount() {
    let mobileBreakPoint = 1200;
    if (window) {
      let windowWidth = window.innerWidth;
      if ((windowWidth) < mobileBreakPoint) {
        this.setState({
          isMobileView: true,
          stagePadding: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        })

      }
    }
  }

  render() {
    const {
      optionalText,
      manuallySelectCaseStudies,
      caseStudiesList,
      customFilterLink,
      customButtonText,
    } = this.props

    const { isMobileView } = this.state;

    const { featuredCaseStudyItem, featuredCaseStudyItemInfo } = caseStudiesList
    const { caseStudies, location, sector } = this.props.data

    const filteredCaseStudies = caseStudies.edges.filter(cs =>
      caseStudiesList
        .map(
          csl => parseInt(csl.featuredCaseStudyItem.guid.match(/\d+$/)[0]),
          10
        )
        .includes(cs.node.wordpress_id)
    )

    let myArr =
      filteredCaseStudies.map((cs, index) => (
        <div className="flex-item__alice" key={index}>
          <div className="image-text__wrapper">
            <div className="image-container">
              <Image
                className="featured-image"
                src={
                  cs.node.featuredImage
                }
              />
            </div>
            <div className="text-container">
              <div className="text-inner-wrapper">
                <span
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: cs.node.title,
                  }}
                />
                {cs.node.sector[0] ? (
                  <span className="line commodity"
                  dangerouslySetInnerHTML={{__html:
                    cs.node.sector[0]
                    ? sector.edges.find(
                        edge =>
                          edge.node.wordpress_id ==
                          cs.node.sector[0]
                      ).node.name
                      : ''
                  }}>
                  </span>
                ) : (
                  'Sector'
                )}
                {cs.node.location[0] ? (
                  <span className="line location">
                    {cs.node.location[0]
                      ? location.edges.find(
                          l =>
                            l.node.wordpress_id ==
                            cs.node.location[0]
                        ).node.name
                      : ''}
                  </span>
                ) : (
                  'Location'
                )}
                <span className="line line__last">
                  {cs.node.acf.companyName ? cs.node.acf.companyName : 'Company Name'}
                </span>
                <p className="blurb">
                  {cs.node.acf.summary}
                </p>
              </div>
              <div className="view-all-button">
                <Link
                  to={`/case-studies/${cs.node.slug}/`}
                  activeClassName="active"
                  className="button"
                  aria-current="true"
                >
                  <span>
                    {customButtonText
                      ? customButtonText
                      : 'View Case Study'}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))

    let aliceSliderSettings = {
      dotsDisabled: this.state.dotsDisabled,
      infinite: true,
      keysControlDisabled: true,
      showSlideInfo: false,
      duration: 450,
      fadeOutAnimation: true,
      stagePadding: this.state.stagePadding,
      responsive: {
        0: {
          items: 1
        },
        1024: {
          items: 1
        }
      },
      onSlideChanged: (e) => handleOnSlideChange(e, myArr.length),
    }

    const handleOnSlideChange = (e, length) => {
      if (e.slide === 0) {
      }
    }

    if (true) {
      return (
        <section className="FeaturedProjectsFeed">
          <div className="wrapper">
            <div className="content-wrapper">
              {optionalText && (
                <div
                  className="wysiwygcontent"
                  dangerouslySetInnerHTML={{ __html: optionalText }}
                />
              )}
            </div>
          </div>
          <OnVisible className="content-overlay">
            <div className="flex-row">
              {/* ALICE CAROUSEL */}
                <Carousel settings={aliceSliderSettings}>
                  {myArr}
                </Carousel>
            </div>
          </OnVisible>
        </section>
      )
    }

    return (
      <section className="ProjectsFeed">
        <div className="background-helper"></div>
        <div className="wrapper">
          <span>No case studies found</span>
        </div>
      </section>
    )
  }
}

export const FeaturedProjectsSliderFeed = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          caseStudies: allWordpressWpCaseStudies(
            sort: { fields: date, order: DESC }
          )
          {
            edges {
              node {
                slug
                title
                location
                sector
                acf {
                  companyName
                  summary
                  isFeatured
                }
                wordpress_id
                featuredImage: featured_image_url {
                  source_url
                }
              }
            }
          }
          location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
          sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
        }
      `}
      render={data => <ProjectsFeedOutput {...props} data={data} />}
    />
  )
}
