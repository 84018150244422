import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './ProjectsFeed.scss';
import { Image } from '../components/Image';
import GatsbyLink from '../components/GatsbyLink';
import { Link } from 'gatsby'
import Slider from 'react-slick';
import { decodeEntities } from '../utils/htmlParse';

class ProjectsFeedOutput extends React.Component {
  render () {

    const shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    const {
      optionalText,
      manuallySelectCaseStudies,
      caseStudiesList,
      customFilterLink,
      customButtonText
    } = this.props;

    const {
      caseStudyItem
    } = caseStudiesList;

    const {
      caseStudies, location, sector
    } = this.props.data;

    //console.log('caseStudies:',caseStudies)
    let dragging = false;

    var sliderSettings = {
      dots: false,
      arrows: false,
      speed: 500,
      slidesToShow: 3,
      // variableWidth: true,
      slidesToScroll: 3,
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ],
    };

    // hack because ACF post object doesn't work with graphql
    const shuffleCaseStudies = shuffle(caseStudies.edges)
    const filteredCaseStudies = shuffleCaseStudies.filter( cs => caseStudiesList.map( csl => parseInt(csl.caseStudyItem.guid.match(/\d+$/)[0]), 10).includes( cs.node.wordpress_id ) ) ;
    if (shuffleCaseStudies) {
      return (
        <section className="ProjectsFeed">
          <div className="background-helper"></div>
          <div className="wrapper">
            <span className="subtitle">Our Projects</span>
            { optionalText && (
              <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: optionalText}} />
            )}
            <div className="content-overlay">
              <div className="flex-row">
                <Slider {...sliderSettings}>
                  {manuallySelectCaseStudies && filteredCaseStudies ? filteredCaseStudies.slice(0, 3).map( (cs, index) =>
                  <div className="flex-item" key={index}>
                    <GatsbyLink
                      to={`/case-studies/${cs.node.slug}/`}
                      decode={false}
                      onClick={(e)=> dragging && e.preventDefault()}
                    >
                      <div className="image-container">
                        <Image className="featured-image" src={cs.node.featuredImage} />
                      </div>
                      <div className="text-container">
                        <span className="title" dangerouslySetInnerHTML={{__html: cs.node.title}}/>
                        { cs.node.location[0] ? <span className="location">{cs.node.location[0] ? location.edges.find( l => l.node.wordpress_id == cs.node.location[0] ).node.name : ''}</span> : ''}
                        {/* <span className="sector">{sector.edges.find( s => s.node.wordpress_id == cs.node.sector ).node.name}</span> */}
                        <div className="read-more">
                          <span className="text">Read More</span>
                          <div className="overflow-hidden">
                            <div className="arrow"></div>
                          </div>
                        </div>
                      </div>
                    </GatsbyLink>
                  </div>
                ) : shuffleCaseStudies && shuffleCaseStudies.slice(0, 3).map( (cs, index) =>

                <div className="flex-item" key={index}>
                  <GatsbyLink
                    to={`/case-studies/${cs.node.slug}/`}
                    decode={false}
                    onClick={(e)=> dragging && e.preventDefault()}
                  >
                    <div className="image-container">
                      <Image className="featured-image" src={cs.node.featuredImage} />
                    </div>
                    <div className="text-container">

                      <span className="title" dangerouslySetInnerHTML={{__html: cs.node.title}}/>
                      { cs.node.location[0] ? <span className="location">{cs.node.location[0] ? location.edges.find( l => l.node.wordpress_id == cs.node.location[0] ).node.name : ''}</span> : ''}
                      <div className="read-more">
                        <span className="text">Read More</span>
                        <div className="overflow-hidden">
                          <div className="arrow"></div>
                        </div>
                      </div>
                    </div>
                  </GatsbyLink>
                </div>
              )}
                </Slider>
              </div>
              <div className="view-all-button center">
                <Link
                  to={ customFilterLink ? `/case-studies/filter?${customFilterLink}` : `/case-studies/`}
                  activeClassName="active"
                  className="button"
                  aria-current="true"
                >
                  <span>{ customButtonText ? customButtonText : 'View All Projects'}</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )
    }

    return (
      <section className="ProjectsFeed">
        <div className="background-helper"></div>
        <div className="wrapper">
          <span>No case studies found</span>
        </div>
    </section>
    )
  }
}

export const ProjectsFeed = (props) => {
  return (
      <StaticQuery
        query={graphql`
          query {
            caseStudies: allWordpressWpCaseStudies(
              sort: { fields: date, order: DESC }
              # limit: 3
            ) {
              edges {
                node {
                  slug
                  title
                  location
                  sector
                  wordpress_id
                  featuredImage: featured_image_url {
                    source_url
                  }
                }
              }
            }
            location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
              edges {
                node {
                  name
                  slug
                  wordpress_id
                  wordpress_parent
                }
              }
            }
            sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
              edges {
                node {
                  name
                  slug
                  wordpress_id
                  wordpress_parent
                }
              }
            }
          }
        `}
        render={data => <ProjectsFeedOutput {...props} data={data} />}
      />
  );
}
