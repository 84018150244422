import React, { Component } from 'react';
import './ContentWithSidebar.scss';
import { Image } from '../components/Image';
import { Link } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink';
import { slugify, isClient } from '../utils/helpers'

export class ContentWithSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: isClient && window.innerWidth > 960 ? 0 : null,
      height: props.height,
      width:props.width
    };

    this.myRef = React.createRef();
  }


  changeItem = (index) => {

    this.setState({
      activeItem: index
    });
    setTimeout(function() {
      this.refs['topOfSection'].scrollIntoView({behavior: "smooth"});
    }.bind(this), 100)
  }


  changeItemMobile = (index) => {
    this.setState({
      activeItem: index
    });
    setTimeout(function() {
      this.refs[index].scrollIntoView({behavior: "smooth"});
    }.bind(this), 100)
  }

  componentDidMount() {
    if ( location.hash ) {
      if ( location.hash.includes('resources') ) {
        setTimeout(function() {
          this.changeItem(0);
        }.bind(this), 100)
      }
      if ( location.hash.includes('industrial-processes') ) {
        setTimeout(function() {
          this.changeItem(1);
        }.bind(this), 100)
      }
      if ( location.hash.includes('infrastructure') ) {
        setTimeout(function() {
          this.changeItem(2);
        }.bind(this), 100)
      }

    }
  }

  render() {
      const props = this.props;

      const {
        sidebarLabel,
        enableDisable,
        sections
      } = props;


      // Need WP url to fetch SVG images
      const { site } = props;
      const { wordpressUrl } = site.siteMetadata;

      return (
        <>
        {/* <span className="anchor-link" ref={(section) => { this.myRef = section; }}></span> */}
        <span className="anchor-link" ref={`topOfSection`}></span>
        <section className={`ContentWithSidebar`}>
          <div className="background-helper"></div>
          <div className="wrapper">
            <div className="content-overlay">
              <div className="flex-row">
                <div className="content sidebar">
                  <div className="inner-sidebar">
                    {sidebarLabel ? (
                      <div className="sidebar-label-container"><span className="sidebar-label" dangerouslySetInnerHTML={{__html: sidebarLabel}} /></div>
                    ) : ''}
                  {sections.map((item, index) => {
                    const {
                      heading,
                      icon
                    } = item;
                    return (
                      <div key={index} className={`sidebar-item ${icon.source_url ? '' : 'no-icon'} ${this.state.activeItem === index ? 'active' : ''}`} onClick={ () => this.changeItem(index) }>
                          { icon.source_url && (
                            <img className={`image`} src={`${wordpressUrl}${icon.source_url}`} />
                          ) }
                          <span className="heading">{heading}</span>
                      </div>
                    );
                  })}
                  </div>
                </div>
                <div className="content content-side">
                  {sidebarLabel ? (
                    <div className="sidebar-label-container"><span className="sidebar-label" dangerouslySetInnerHTML={{__html: sidebarLabel}} /></div>
                  ) : ''}
                  {sections.map((item, index) => {
                    const {
                      heading,
                      content,
                      buttonOne,
                      buttonOneText,
                      buttonOneHash,
                      buttonTwo,
                      buttonTwoText,
                      buttonTwoHash,
                      contactCards
                    } = item;
                    const buttonOneUrl = `${buttonOne}${buttonOneHash && `${buttonOneHash}`}`
                    const buttonTwoUrl = `${buttonTwo}${buttonTwoHash && `${buttonTwoHash}`}`
                    return (
                      <div className="content-item-container" key={index}>
                        <span className="anchor-link" ref={index}></span>
                        <button className={`mobile-accordion-button ${this.state.activeItem === index ? 'active' : ''}`} onClick={ () => this.changeItemMobile(index)}>{heading}</button>
                        <div className={`content-item ${this.state.activeItem === index ? 'show' : 'hide'}`}>
                          <h2 className="heading">{heading}</h2>
                          { enableDisable &&
                            enableDisable.includes('enableContent') ? (
                              <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: content}} />
                            ) : ''
                          }
                          {
                            enableDisable &&
                            enableDisable.includes('enableButtons') ? (
                              <div className="buttons">
                                {buttonOneUrl && (
                                  <Link
                                    to={buttonOneUrl}
                                    activeClassName="active"
                                    className={`button`}
                                    aria-current="true"
                                  >
                                    <span>{ buttonOneText ? buttonOneText : 'Learn More' }</span>
                                  </Link>
                                )}
                                {buttonTwoUrl && (
                                  <Link
                                    to={buttonTwoUrl}
                                    activeClassName="active"
                                    className={`button`}
                                    aria-current="true"
                                  >
                                    <span>{ buttonTwoText ? buttonTwoText : 'Learn More' }</span>
                                  </Link>
                                )}
                              </div>
                            ) : ''
                          }
                          { enableDisable &&
                            enableDisable.includes('enableContactCards') ? (
                              <div className="contact-card-container">
                                {contactCards.map((item, index) => {
                                  const {
                                    locationName,
                                    streetAddress,
                                    mailingAddress,
                                    telephone,
                                    fax,
                                    googleMapsLink
                                  } = item;
                                  return (
                                    <div className="contact-card" key={index}>
                                      { locationName && (
                                        <span className="location-name" dangerouslySetInnerHTML={{__html: locationName}} />
                                      )}
                                      { streetAddress && (
                                        <span className="street-address" dangerouslySetInnerHTML={{__html: streetAddress}} />
                                      )}
                                      { mailingAddress && (
                                        <span className="mailing-address" dangerouslySetInnerHTML={{__html: mailingAddress}} />
                                      )}
                                      { telephone && (
                                        <span className="telephone">Telephone: {telephone}</span>
                                      )}
                                      { fax && (
                                        <span className="fax">Fax: {fax}</span>
                                      )}
                                      { googleMapsLink && (
                                        <GatsbyLink to={googleMapsLink} className={`button`} aria-current="true" target="_blank">View On Maps</GatsbyLink>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : ''
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
      )
    }
  }
