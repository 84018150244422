import React from 'react';
import './SteppedPanels.scss';
import { Image } from '../components/Image';
import { Link } from 'gatsby'
import OnVisible, { setDefaultProps } from 'react-on-visible';

setDefaultProps({
    percent: 25
});

export const SteppedPanels = props => {
  const {
    // theme,
    subheading,
    heading,
    content,
    panels
  } = props

  // const { wordpressUrl } = site.siteMetadata;

  return (
    <section className={`SteppedPanels dark`}>
      <div className="background-helper"></div>
      <div className="wrapper">
        <span className="subtitle" dangerouslySetInnerHTML={{__html: subheading}} />
        <div className="wysiwygcontent">
          <h2 className="heading" dangerouslySetInnerHTML={{__html: heading}} />
          <div className="" dangerouslySetInnerHTML={{__html: content}} />
        </div>
        <div className="content-overlay">
          <div className="flex-row">
            { panels.map( (item, index) => {
              const {
                title,
                description,
                image
              } = item;

              return (
                <OnVisible className="panel" key={index}>
                {/* <div className="panel" key={index}> */}
                  <div className="image-container">
                  { image && (
                    <Image className="background-image" src={image} />
                  ) }
                  </div>
                  <div className="text-area">
                    <span className="heading" dangerouslySetInnerHTML={{__html: title}} />
                    <span className="description" dangerouslySetInnerHTML={{__html: description}} />
                  </div>
                {/* </div> */}
                </OnVisible>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
