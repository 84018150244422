import React from 'react';
import { FaTimes } from 'react-icons/fa';
import GatsbyLink from '../../components/GatsbyLink'
import { slugify, compileQuery } from '../../utils/helpers'
import './Menu.scss'
export const Menu = (props) => {

  const {
    state,
    changeRegion,
    toggleMapLocation,
    allRegion,
    allLocations
  } = props

  const {
    mapRegions,
    activeRegion,
    activeLocation,
    activeCenter,
    //regionIcon,
    //projectTotal,
    //studiesTotal,
    //commodities,
    mapZoom,
    transitionDirection,
    openInformation
  } = state

  const activeCaseStudies = activeRegion !== null && activeLocation !== null ? mapRegions[activeRegion].locations[activeLocation].projects.filter(project => project.slug) : null
  const activeProjects = activeRegion !== null && activeLocation !== null ? mapRegions[activeRegion].locations[activeLocation].projects.filter(project => !project.slug) : null

  return (

    <div className="menu">

      <h3>Locations</h3>

      <div className="menu-inner">

        {activeRegion === null && !activeLocation && //Region list
          <div className={`regions-menu ${transitionDirection}`}>
            {mapRegions.map((region, index) => region.regionName &&
              <div className="title link" key={index} onClick={event => changeRegion(event, region.regionIndex)}>
                <span>{region.regionName}</span>
                <button className="open"/>
              </div>
            )}
          </div>
        }

        {activeRegion !== null && activeLocation === null && //Locations List
          <div className={`locations-menu ${transitionDirection}`}>
            <div className="title">
              <button className="close link" onClick={event => allRegion(event)} />
              <span>{mapRegions[activeRegion].regionName}</span>
            </div>
            <div className="scroll">
            {mapRegions[activeRegion].locations.map((location, index) =>
              <div className="sub-title link" key={index} onClick={event => toggleMapLocation(event, index, location.lat, location.lng, activeRegion)}>
                <span>{location.location}</span>
                <button className="open"/>
              </div>
            )}
            </div>
          </div>
        }

        {activeRegion !== null && activeLocation !== null && //Projects List
          <div className={`projects-menu ${transitionDirection}`}>
            <div className="title">
              <button className="close link" onClick={event => allLocations(event)} />
              <span>{mapRegions[activeRegion].locations[activeLocation].location}</span>
            </div>
            <div className="scroll">
            {activeCaseStudies.length > 0 &&
              <>
              <div className="intro-title">Case Studies</div>
              {activeCaseStudies.map((project, index) =>
                <div key={index} className="project">
                  <div className="sub-title">
                    <GatsbyLink to={`/case-studies/${project.slug}/`} decode={false} className="link">
                    <div className="name">{project.title}{project.commodity &&  project.commodity !== '???' && ` | ${project.commodity}`}</div>
                    {project.client && <span className="client">{project.client}</span>}
                    </GatsbyLink>
                  </div>
                </div>
              )}
              </>
            }

            {activeProjects.length > 0 &&
              <>
              <div className="intro-title">Projects</div>
              {activeProjects.map((project, index) =>
                <div key={index} className="project">
                    <div className="sub-title">
                      <span className="name">{project.title}{project.commodity &&  project.commodity !== '???' && ` | ${project.commodity}`}</span>
                      {project.client && <span className="client">{project.client}</span>}
                    </div>
                </div>
              )}
              </>
            }
            </div>
          </div>
        }

      </div>

      {activeRegion !== null && activeLocation !== null && mapRegions && //Show view all case studies
        <GatsbyLink to={`/case-studies/filter${compileQuery('location',slugify(mapRegions[activeRegion].locations[activeLocation].location),'regionName')}`} decode={false} className="case-study-link read-more"><span className="text">{mapRegions[activeRegion].locations[activeLocation].location} case studies</span><div className="arrow"></div></GatsbyLink>
      }
    </div>

  )
}
