import React, { useState, useEffect } from 'react'
import RenderContent from '../../components/RenderContent'
import './ReportList.scss'

export const ReportList = props => {
  const { reportContent } = props;

  /* useEffect hook to add the expanded classes on first element */
  useEffect(() => {
    const wrapperElement = document.querySelector('.report-list__container__wrapper');
    const firstGroup = wrapperElement.firstElementChild;
    setInitialExpanded(firstGroup);
  })

  function setInitialExpanded(elem) {
    elem.querySelector('button').classList.toggle('expanded');
    elem.querySelector('.report-row__wrapper').classList.toggle('expanded');
  } 

  return (
    <section
      className={props.className ? props.className : 'report-list__container'}>
      <div className="wrapper report-list__container__wrapper">
        {reportContent && reportContent.length > 0 && 
          reportContent.map((reportGroup, index) => 
            <ReportGroup key={index} reportGroup={reportGroup} />
        )}
      </div>
    </section>
  )
}

// Report Item handles a report group, containing the year and
// associated reports
const ToggleButton = () => {
  const [isExpanded, setExpand] = useState(false);
  const [rowNode, setRowNode] = useState(null);
  const [buttonTarget, setButtonTarget] = useState(null);

  function handleExpand() {
    setExpand(!isExpanded);
    setRowNode(event.target.parentNode.nextSibling);
    setButtonTarget(event.target);
  }

  useEffect(() => {
    if (rowNode && buttonTarget) {
      rowNode.classList.toggle('expanded');
      buttonTarget.classList.toggle('expanded');
    }
  })

  return (
    <button onClick={handleExpand}>
    </button>
  )
}

const ReportGroup = ( {reportGroup} ) => {
  
  const { reports } = reportGroup;
  return (
    <div className="report-year-group">
      <div className="year__wrapper">
        <h2>{reportGroup.year}</h2>
        <ToggleButton />
      </div>
      <div className="report-row__wrapper">
        {reports.map((item, index) =>
          <ReportItem key={index} reportItem={item} />
        )}
      </div>
    </div>
  )
}

const ReportItem = item => {
  const { reportTitle, reportFile } = item.reportItem;
  if (reportFile) {
    if (!reportTitle) {
      reportTitle = reportFile.name;
    }
  }

  return (
    reportFile && reportTitle &&
      <div className="report-row">
        <div className="title">{reportTitle}</div>
        {/* We need to check for a file before rendering the download button */}
        {reportTitle && reportFile.url && <a className="button button-download" href={reportFile.url}>Download</a>}
      </div>
  )
}

