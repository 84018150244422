import React from 'react'
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import * as Scroll from 'react-scroll';
import Slider from 'react-slick';
import './HomeHero.scss'
import { Image } from '../../components/Image'
import ArrowDown from '../../components/ArrowDown/ArrowDown'
import PageGrid from '../../components/PageGrid/PageGrid'

let Link       = Scroll.Link;
let Element    = Scroll.Element;
let Events     = Scroll.Events;
let scroll     = Scroll.animateScroll;
let scrollSpy  = Scroll.scrollSpy;

class HomeHeroClass extends React.Component {
  constructor(props) {
    super(props)
    // let isIE = false;
    this.state = {
      isIE: false,
      isFirstHeadingActive: true,
      isSecondHeadingActive: false,
      homeSectionClassName: 'HomeHero',
      lockScroll: false,
    }
  }; // for scroll delaying
  componentDidMount() {
    this.setState({
      isIE:
      '-ms-scroll-limit' in document.documentElement.style &&
      '-ms-ime-align' in document.documentElement.style,
      lockScroll: true,
    })
    window.addEventListener('wheel', () => {
      this.debounce(this.handleWheel, 10)
    }, true);
    window.addEventListener('touchmove', () => {
      this.debounce(this.handleWheel, 10)
    }, {passive: false});
    Events.scrollEvent.register('begin', function(to, element) {
    });
    Events.scrollEvent.register('end', function(to, element) {
    });
    scrollSpy.update();
  }
  /* debounce method prevents rapid firing of window scroll event listener */
  debounce(method, delay) {
    clearTimeout(method._tId);
    method._tId= setTimeout(function(){
        method();
    }, delay);
  }

  handleWheel = () => {
    let firstHeading = document.querySelector('.first-header');
    /* Make sure your state change is actually delayed by the anim transition
     * in the sass file, as unmounting is instant.
     */
    let delaySpeed = 500;
    if (firstHeading) {
      firstHeading.classList.add('fade-up');
      setTimeout(() => {
        this.setState({isFirstHeadingActive: false, isSecondHeadingActive: true})
      }, delaySpeed)
      /* Remove scroll lock after 1.4s */
      setTimeout(() => {
        window.removeEventListener('wheel', () => {}, true);
        this.setState({lockScroll: false})
      }, 1400);
    }
  }

  scrollMore = (value) => {
    scroll.scrollMore(value);
  }

  handleScroll = () => {

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('wheel', this.handleScroll, true);
    window.removeEventListener('touchmove', this.handleScroll, true);
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');

    // We need to remove this class from body to prevent the added background colour via the class.
    if (document.querySelector('body').classList.contains('new-bg')) {
      document.querySelector('body').classList.remove('new-bg');
    }
  }

  handleClick() {
    if (this.state.isSecondHeadingActive === true) {
      // nextArea.scrollIntoView(true);
    }
    if (this.state.lockScroll === false) {
      let windowH = window.innerHeight;
      this.scrollMore(windowH);
    } else {
      this.setState({isFirstHeadingActive: false, isSecondHeadingActive: true})
      window.removeEventListener('wheel', () => {}, true);
      this.setState({lockScroll: false})
    }
  }

  render() {
    const {
      heading,
      backgroundImage,
      ctaButton,
      buttonText,
      multiHeading,
      backgroundImageCarousel,
      backgroundImageCarouselDuration,
    } = this.props;
    const {
      isFirstHeadingActive,
      isSecondHeadingActive,
      homeSectionClassName,
    } = this.state;
    const { headerOne, headerTwo } = multiHeading[0]
    const sliderSettings = {
      dots: false,
      arrows: false,
      fade: true,
      autoplay: true,
      infinite: true,
      speed: 2000, // time for fade anim
      autoplaySpeed: 5001,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <>
        <section
        className={`${homeSectionClassName} ${this.state.isIE ? 'isIE' : ''}`}
        id="home-hero-section"
        onScroll={this.handleScroll}
      >
        <ScrollLock isActive={this.state.lockScroll} />
          <div className="video-wrapper background-wrapper">
            <div className="grid">
              <PageGrid />
            </div>
            <div className="video-overlay">
            </div>
            <video className="video-element" autoPlay playsInline muted loop>
              <source src="/videos/Lyco_Homepage_Video_V2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="content-container">
            <div className="content-position-helper wrapper">
              <div className="content">
                <div className="h1-wrap" id="header-one-scrollable">
                  {isFirstHeadingActive && (
                    <h1
                      className={'sliding-header first-header'}
                      dangerouslySetInnerHTML={{ __html: headerOne }}
                    />
                  )}
                  {isSecondHeadingActive && (
                    <h1
                      className={'sliding-header second-header'}
                      dangerouslySetInnerHTML={{ __html: headerTwo }}
                    />
                  )}
                </div>
              </div>
              <div className="arrow-wrap" onClick={(e) => this.handleClick(e)}>
                <ArrowDown autoMode={false}/>
              </div>
            </div>
          </div>
      </section>
      </>
    )
  }
}

const BackgroundImageSlider = props => {
  <div className="background-wrapper">
    <div className="background-image__wrapper">
      <div className="grid">
        <PageGrid />
      </div>
      <Slider {...sliderSettings}>
      {backgroundImageCarousel &&
        backgroundImageCarousel.map((image, index) => (
          <div key={index} className="item">
            <Image className="background-image" src={image} />
          </div>
        ))}
    </Slider>
    </div>
  </div>
}

export const HomeHero = props => <HomeHeroClass {...props} />
