import React from 'react'
import OnVisible, { setDefaultProps } from 'react-on-visible'
import { Link } from 'gatsby'
import ScrollingColorBackground from 'react-scrolling-color-background';
import { Image } from '../../components/Image';
import './HomeTextAndVideoImage.scss';



export const HomeTextAndVideoImage = props => {
  const {
    subtitle,
    heading,
    copyContent,
    image,
    imageAccent,
    buttonLink,
    buttonText
   } = props;

   return (
    <section className="home-text-and-video" data-background-color={'rgb(29, 191, 104)'}>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <OnVisible percent={10} className="content text-box tb2">
              <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
              <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} />
              <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: copyContent}} />
              <div className="image-wrapper">
                {image && <Image src={image} position="relative"/>}
                {imageAccent && <div className="image-accent">
                  <div className="block-one">
                  </div>
                  <div className="block-two">
                  </div>
                </div>}
              </div>
              {buttonLink && (
                  <Link
                    to={buttonLink}
                    activeClassName="active"
                    className="button on-light"
                    aria-current="true"
                  >
                    <span>{ buttonText ? buttonText : 'Join Us' }</span>
                  </Link>
              )}
            </OnVisible>
          </div>
        </div>
      </div>
    </section>
    )
}
