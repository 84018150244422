import React from 'react';
import './Cta.scss';
import { Image } from '../components/Image';
import { Link } from 'gatsby'
import OnVisible, { setDefaultProps } from 'react-on-visible';

setDefaultProps({
  percent: 20
});

export const Cta = props => {
  const {
    heading,
    content,
    button,
    buttonHash,
    buttonText,
    backgroundImage
  } = props

  const buttonUrl = `${button}${buttonHash && `${buttonHash}`}`
  return (
    <section className="Cta">
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <OnVisible className="content text-box tb2" percent={10}>
              <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} />
              <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: content}} />
              {buttonUrl && (
                  <Link
                    to={buttonUrl}
                    activeClassName="active"
                    className="button on-dark"
                    aria-current="true"
                  >
                    <span>{ buttonText ? buttonText : 'Learn More' }</span>
                  </Link>
              )}
            </OnVisible>
          </div>
        </div>
      </div>
      { backgroundImage && (
        <Image className="background-image" src={backgroundImage}/>
      ) }
    </section>
  )
}
